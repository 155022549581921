import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
/* IconFont import */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
/* Image import */
import LogoPink from "../images/LogoPink.png"
import FvMock from "../images/fv-mock.png"
import appstore from "../images/appstore.svg"
import googlePlay from "../images/google-play-badge.png"
import stress1 from "../images/stress1.png"
import stress2 from "../images/stress2.png"
import stress3 from "../images/stress3.png"
import stress4 from "../images/stress4.png"
import stress5 from "../images/stress5.png"
import stressNurse from "../images/stressNurse.png"
import down from "../images/down.png"
import NurseImg1 from "../images/NurseImg1.png"
import NurseImg2 from "../images/NurseImg2.png"
import NurseImg3 from "../images/NurseImg3.png"
import mockSeikaku from "../images/mockSeikaku.png"
import mockStress from "../images/mockStress.png"
import mockCalendar from "../images/mockCalendar.png"
import titleSeikaku from "../images/titleSeikaku.png"
import titleStress from "../images/titleStress.png"
import titleCalendar from "../images/titleCalendar.png"
import imgUsersVoices from "../images/UsersVoices.png"
import Voice from "../images/Voice.png"
import MockOld from "../images/MockOld.png"
import imgUserData from "../images/imgUserData.png"
import DataGirl from "../images/DataGirl.png"

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ナースコール"/>
      <section className="Home" id="pagetop">
        <div className="firstview">
          
          <div className="wrap firstview-wrap">
            <div>
              <h2>頑張る自分に<br></br>ナースコール</h2>
            </div>
            <img src={ FvMock } className="fv-mock" alt="画面イメージ" />
            <div className="FvUnder">
              <h1 className="ProjectFlag">NuRseCallは、心身共にストレスの多い現場で頑張る看護師さんたちの為の、ストレスマネジメントアプリです。</h1>
              <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
                <img src={ appstore } className="appstore ml-10" alt="App Storeからダウンロード" />
              </a>
              <a target="_blank" href='https://play.google.com/store/apps/details?id=com.nurse_support.nursecall&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Google Play で手に入れよう' className="appstore ml-10" src={ googlePlay }/>
              </a>
            </div>
          </div>
          <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
        </div>

        <div className="ProblemPresentation">
          <div className="wrap">
            <h2>コロナ禍で過酷さを増す看護師のストレス環境</h2>
            <p className="TaCenter">看護師として働くあなたは、ストレスをため込んでしまっていませんか？<br></br>
  多くの問題があっても、患者さんが安心できるように笑顔を絶やさず頑張っていますよね？<br></br>
  つい無理をしてストレスをため込んでしまい、「つらい、辞めたい」という気持ちになりませんか？
            </p>
            <div className="stress-girl">
              <img src={ stress1 } alt="仕事の責任が大きい" />
              <img src={ stress2 } alt="人間関係が上手くいかない" />
              <img src={ stress3 } alt="お給料が少ない" />
              <img src={ stress4 } alt="仕事が多くて終わらない" />
              <img src={ stress5 } alt="コロナ禍でストレス発散できない" />
            </div>
            <div className="stress-nurse">
              <img src={ stressNurse } alt="コロナ禍でも頑張るナース" />
              <p>
              看護師は、人の生死に関わる緊張や不安の強い業務や、交代勤務制により生体リズムが乱れやすいことから常に
  強いストレスを感じています。<br></br>
  さらに、感染が拡大する新型コロナウイルスの影響により、人員不足による長時間勤務、社会的スティグマ、
  ストレスコントロールのための外出などの活動が制限され、看護師を取り巻くストレス環境は悪化し続けています。
              </p>
            </div>
            <h3>
            ナースのストレスを少しでも減らすお手伝いがしたい！！
            </h3>
            <img src={ down } className="down"/>
            <div className="About">
              <img src={ NurseImg2 } className="NurseImg2"/>
              <p>
                <strong>NuRseCall（ナースコール）</strong>は<br></br>
                ストレスフルな現場で頑張る看護師の皆さんが心と体を健全に保ち、みずみずしい生活を送れるようサポートすることを目標に創られました。<br></br><br></br>
                <img src={ LogoPink } className="AboutLogo"/>　のロゴは<br></br>
                Rを反転(Reverse)させることで、自分自身へのナースコールという意味をもたせています。
              </p>
            </div>

          </div>
        </div>

        <div className="CTA1">
          <div className="wrap">
            <h2>ナースのための機能を、<br className="pcDispNone"></br>ひとつのアプリで。</h2>
            <div className="cont">
              <div>

                <ul>
                  <li className="bg-aqua"><p>性格診断</p></li>
                  <li className="bg-pink"><p>ストレス<br></br>チェック</p></li>
                  <li className="bg-yellow"><p>シフト<br></br>カレンダー</p></li>
                </ul>
                <p>
                『NuRseCall』は看護師に特化したストレスチェックを気軽に・簡単に実施し、心と体のセルフケアをサポートするアプリです。<br></br>
                また、ストレスチェックを継続的にご利用頂くために、毎日利用するシフト管理・共有ができる機能を追加しました。<br></br>
                今後も、ナースに役立つ機能やサービスを展開していきます。
                </p>
                <p className="download-text">ダウンロードはこちら</p>
                <div className="download-box-center mb-10">
                  <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
                    <img src={ appstore } className="appstore-150 ml-10" alt="App Storeからダウンロード" />
                  </a>
                  <a target="_blank" href='https://play.google.com/store/apps/details?id=com.nurse_support.nursecall&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Google Play で手に入れよう' className="appstore-150 ml-10" src={ googlePlay }/>
                  </a>
                </div>
                <div className="download-box">
                  <img src={ NurseImg3 } className="NurseImg3 pcDispNone"/>
                </div>              
              </div>
              <img src={ NurseImg1 } className="NurseImg1 spDispNone"/>
            </div>
          </div>
        </div>

        <div className="SeikakuShindan">
          <div className="wrap">
            <div className="tryContents">
              <img src={ titleSeikaku } className="titleImg" alt="自分の性格タイプをチェックしよう"/>
              <p>
              ５０個の簡単な質問に答えることで、性格タイプを判定し、あなたの性質やアドバイスをお知らせします
              </p>
              <Link to="/app#AppSeikakuShindan" className="moreButton" >
              詳しく見る <FontAwesomeIcon icon={faChevronCircleRight} />
              </Link>
            </div>
            <img src={ mockSeikaku } className="mockImg"/>
          </div>
        </div>

        <div className="StressCheck">
          <div className="wrap">
            <div className="tryContents">
              <img src={ titleStress } className="titleImg" alt="ストレスればるをチェックしよう"/>
              <p>
              月4回、少しの質問に答えるだけで、ストレスレベルとストレス反応・ストレス要因が毎月確認できます
              </p>
              <Link to="/app#AppStressCheck" className="moreButton" >
              詳しく見る <FontAwesomeIcon icon={faChevronCircleRight} />
              </Link>
            </div>
            <img src={ mockStress } className="mockImg"/>
          </div>
        </div>

        <div className="Calendar">
          <div className="wrap">
            <div className="tryContents">
              <img src={ titleCalendar } className="titleImg" alt="シフトを簡単入力してシェアしよう"/>
              <p>
              シフトや予定をカレンダーで管理でき、職場や同期仲間と<span className="pink">シフトの共有</span>も可能！シフトは簡単に<span className="pink">連続で登録</span>でき、<span className="pink">１日に３件まで登録が可能</span>です。
              </p>
              <Link to="/app#AppCalendar" className="moreButton" >
              詳しく見る <FontAwesomeIcon icon={faChevronCircleRight} />
              </Link>
            </div>
            <img src={ mockCalendar } className="mockImg"/>
          </div>
        </div>

        <div className="UsersVoices">
          <div className="wrap">
            <img src={ imgUsersVoices } className="imgUsersVoices" alt="User's Voices"/>
            <h2>
              すでに多くの看護師さんにご利用いただいています♪
            </h2>
            <img src={ MockOld } className="MockOld"/>
            <img src={ Voice } className="Voice" alt="自分のストレスがこんなに高いとは思っていなくてびっくりしました。ストレスを客観的に見られるし、簡単だから忙しくても続けられそう♪"/>
          </div>
        </div>


        <div className="UsersData">
          <div className="wrap">
            <h2>
            NuRseCallアプリを利用している<br></br><span className="pink">看護師</span>の<span className="pink">約３割</span>が<span className="pink">高ストレス</span>状態でした
            </h2>
            <img src={ imgUserData } className="imgUserData"/>
            <div className="merit">
              <p>
              頑張り屋さんのあなたも、気がついていないだけで<span className="pink">ストレスが溜まっているかも</span>しれません・・・<br></br><br></br>
              ストレスの度合いや<span className="aqua">要因が明確</span>になることで<span className="aqua">ストレスが軽減</span>され、<span className="aqua">ケアもしやすく</span>なりますよ。
              </p>
              <img src={ DataGirl } className="DataGirl"/>
            </div>
          </div>
        </div>

        <div className="CTA2">
          <div className="wrap">
            <div className="TaCenter">
              <h2>
                <p className="TaLeft">あなたも</p>
                頑張る自分に<br></br>
                ナースコール<br></br>
                <p className="TaRight">しませんか？</p>
              </h2>
              <h3>ナースのための機能を、ひとつのアプリで。</h3>
              <ul>
                <li><p>性格診断</p></li>
                <li><p>ストレス<br></br>チェック</p></li>
                <li><p>シフト<br></br>カレンダー</p></li>
              </ul>
            </div>
            <img src={ FvMock } className="FvMock"/>
            <p className="download-text">ダウンロードはこちら</p>
            <div className="download-box-center mb-10">
              <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
                <img src={ appstore } className="appstore-150 ml-10" alt="App Storeからダウンロード" />
              </a>
              <a target="_blank" href='https://play.google.com/store/apps/details?id=com.nurse_support.nursecall&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Google Play で手に入れよう' className="appstore-150 ml-10" src={ googlePlay }/>
              </a>
            </div>
          </div>
          <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
        </div>
      </section>

      

    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
